// import * as UserApi from '../api/user';
// import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux'
import { TrackingData } from 'src/api/models/ai'
import { api } from 'src/api'

// action types
export const aiTypes = {
  // AI
  AI_REQUEST: 'AI/SET_REQUEST',
  AI_SUCCESS: 'AI/SET_SUCCESS',
  AI_ERROR: 'AI/SET_ERROR',

  // Tracking
  TRACKING_REQUEST: 'TRACKING/SET_REQUEST',
  TRACKING_SUCCESS: 'TRACKING/SET_SUCCESS',
  TRACKING_ERROR: 'TRACKING/SET_ERROR'
}

export interface AiState {
  vehicles?: {
    model: string
    count: number
  }[]
}

// initial state
const initialState: AiState = {}

// reducer
export const aiReducer = (
  state: AiState = initialState,
  action: any
): AiState => {
  switch (action.type) {
    // login
    case aiTypes.AI_SUCCESS:
      let existingData = !!state.vehicles?.length ? [...state.vehicles] : []
      const isExisting = existingData?.find(
        (x) => x.model?.toUpperCase() === action.model?.toUpperCase()
      )
      if (!isExisting) {
        existingData.push({
          model: action.model?.toUpperCase(),
          count: action.count
        })
      } else {
        existingData = existingData?.map((m) => {
          return {
            ...m,
            count:
              m.model?.toUpperCase() === action.model?.toUpperCase()
                ? m.count + action.count
                : m.count
          }
        })
      }
      return {
        ...state,
        vehicles: existingData
      }
    case aiTypes.TRACKING_SUCCESS:
      return {
        ...state,
        vehicles: undefined
      }
    case aiTypes.TRACKING_ERROR:
      return {
        ...state
      }

    default:
      return state
  }
}

// action creators & async actions
export const aiActions = {
  savePageView: (model: string, count: number) => async (
    dispatch: Dispatch
  ) => {
    dispatch({
      type: aiTypes.AI_SUCCESS,
      model,
      count
    })
  },
  sendTrackingCount: (data: TrackingData) => async (dispatch: Dispatch) => {
    try {
      const response = await api.ai.sendTrackingCount(data)

      if (response.data?.meta?.responsecode === 200) {
        dispatch({ type: aiTypes.TRACKING_SUCCESS })
        return {
          data: response?.data?.data,
          responsecode: response.data?.meta?.responsecode,
          status: response.data?.meta?.status,
          message: ''
        }
      } else {
        return {
          data: undefined,
          responsecode: response.data?.meta?.responsecode,
          status: response.data?.meta?.status,
          message: response.data?.meta?.message
        }
      }
    } catch (error) {
      dispatch({
        type: aiTypes.TRACKING_ERROR,
        data: { errorMessage: 'Invalid details!' }
      })
      return {
        data: undefined,
        responsecode: error.response.data?.meta?.responsecode,
        status: error.response.data?.meta?.status,
        message: error.response.data?.meta?.message
      }
    }
  }
}
